// monochrome theme
.default-color-scheme {
  .reverse {
    color: #fff; }

  .light {
    color: #555555; }

  .medium {
    color: #333333; }

  .dark {
    color: #000; }

  .pre-dark {
    color: #222; }

  .bg-dark {
    background-color: #525252; }

  .bg-light {
    background-color: #ffffff; }

  .bg-medium {
    background-color: #f1f1f1; }

  .border-light {
    border-color: #f1f1f1; }

  .border-light-hover:hover {
    border-color: #999; }

  .border-medium {
    border-color: #ddd; }

  .border-medium-hover:hover {
    border-color: #777; }

  .border-dark {
    border-color: #555; }

  .border-menu {
    border-color: #fff; }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(84,84,84,0.1); }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(84,84,84,0.5); }

  ::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0); }

  * {
    scrollbar-color: #f1f1f1 rgba(84,84,84,0.1); } }

// blue theme
.blue-color-scheme {
  .reverse {
    color: #fff; }

  .light {
    color: #35395A; }

  .medium {
    color: #35395A; }

  .dark {
    color: #35395A; }

  .pre-dark {
    color: #35395A; }

  .bg-dark {
    background-color: #505F96; }

  .bg-light {
    background-color: #F8F8FC; }

  .bg-medium {
    background-color: #EBEBF5; }

  .border-light {
    border-color: #eaeafd; }

  .border-light-hover:hover {
    border-color: #9292b1; }

  .border-medium {
    border-color: #d1d1fc; }

  .border-medium-hover:hover {
    border-color: #5f6386; }

  .border-dark {
    border-color: #35395A; }

  .border-menu {
    border-color: #fff; }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; }

  ::-webkit-scrollbar-thumb {
    background-color: #505f9628; }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #505f96b4; }

  ::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0); }

  * {
    scrollbar-color: #f1f1f1 #505f9628; } }

// purple theme
.purple-color-scheme {
  .reverse {
    color: #fff; }

  .light {
    color: #6A617F; }

  .medium {
    color: #6A617F; }

  .dark {
    color: #6A617F; }

  .pre-dark {
    color: #6A617F; }

  .bg-dark {
    background-color: #8257E5; }

  .bg-light {
    background-color: #F8F8FC; }

  .bg-medium {
    background-color: #EBEBF5; }

  .border-light {
    border-color: #EBEBF5; }

  .border-light-hover:hover {
    border-color: #9c9cfa; }

  .border-medium {
    border-color: #bbaae2; }

  .border-medium-hover:hover {
    border-color: #6A617F; }

  .border-dark {
    border-color: #6A617F; }

  .border-menu {
    border-color: #fff; }

  ::-webkit-scrollbar-track {
    background-color: #f1f1f1; }

  ::-webkit-scrollbar-thumb {
    background-color: #8257e546; }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #8257e59d; }

  ::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0); }

  * {
    scrollbar-color: #f1f1f1 #8257e546; } }

// brown theme
.brown-color-scheme {
  .reverse {
    color: #fff; }

  .light {
    color: #a77a5a; }

  .medium {
    color: #805f45; }

  .dark {
    color: #684c38; }

  .pre-dark {
    color: #684c38; }

  .bg-dark {
    background-color: #966D50; }

  .bg-light {
    background-color: #FFF8ED; }

  .bg-medium {
    background-color: #faeddb; }

  .border-light {
    border-color: #f8ead6; }

  .border-light-hover:hover {
    border-color: #e4bea3; }

  .border-medium {
    border-color: #e2c0a8; }

  .border-medium-hover:hover {
    border-color: #af8160; }

  .border-dark {
    border-color: #966D50; }

  .border-menu {
    border-color: #fff; }

  ::-webkit-scrollbar-track {
    background-color: #fdf1de; }

  ::-webkit-scrollbar-thumb {
    background-color: #daae8f94; }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #966d5094; }

  ::-webkit-scrollbar-corner {
    background-color: rgba(0, 0, 0, 0); }

  * {
    scrollbar-color: #fdf1de #daae8f94; } }
