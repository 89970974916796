@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  min-width: 320px;
  min-height: 320px;
  overscroll-behavior-x: none; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: Roboto, sans-serif;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); }

#root {
  min-height: 100vh; }

$scrollbarSize: 10px;

::-webkit-scrollbar {
  height: $scrollbarSize;
  width: $scrollbarSize; }

* {
  scrollbar-width: thin; }
